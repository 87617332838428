:root {
  --primary: #63b4ce;
  --sidebarbg: #003c69;
  --authBg: #f4faff;
  --white: #ffffff;
  --orange: #ff6636;
}

/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  width: 4px;
  height: 0px;
  border-radius: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background:transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*  modal css */
.allmodal .ant-modal-content {
  padding: 0px !important;
}
/* custom-table.css */

.antd-table thead.ant-table-thead tr th {
  background-color: var(--primary);
  color: white;
  font-weight: 700;
  padding: 10px 20px;
  border-radius: 0px !important;
  font-size: 13px;
  text-align: start !important;
  @apply font-inter
  /* Set your desired background color */
  /* Add any other styles you want for the table header */
}
.antd-table th.ant-table-cell .ant-table-column-has-sorters {
  /* background-color: var(--bs-gray-100) !important; */
  text-align: center !important;
}
.antd-table th.ant-table-cell {
  text-align: center;
  border-radius: 0px !important;
}
.antd-table .ant-table-tbody > tr > td {
  padding: 10px 20px;
  text-align: start;
  @apply font-dmsans capitalize
}

.antd-table .ant-table-tbody > tr {
  height: 2px;
  line-height: 24px;
}
.ant-table-filter-dropdown .ant-table-filter-dropdown-btns button:nth-child(2) {
  background-color: #113150 !important;
}
.antd-table .ant-table-tbody{
  background-color: #ffffff;
}
.table-rows .ant-table-tbody {
  background-color: #162636;
  color: #ffffff;
}

.table-rows .ant-table-tbody > tr:hover {
  color: #000000;
}
.ant-switch-checked {
  background-color: var(--primary) !important;
}


/* PAGINATION CSS */
.ant-pagination-item {
  background: none !important;
  @apply font-bold text-[#6C757D] font-inter;
}
.ant-pagination-item-active {
  border: none !important;
  background-color: var(--primary) !important;
  color: white !important;
  border-radius: 50px !important;
  @apply bg-primary text-white;
}
.ant-pagination-item-active a {
  color: white !important;
}

.unitfield .ant-space-item{
  width: 100% !important;
}

.ant-select-selector {
  border-radius: 0px !important;
}

.ant-picker-header{
  background-color: var(--primary) !important;
  color: white !important;
  @apply hover:text-white
}

.chart-select .ant-select-selector{
  border-radius: 50px !important;
  text-align: center !important;
  border: none !important;
  color: white !important;
  background-color: rgb(38, 192, 38) !important;
  width: 150px !important;
  /* @apply rounded-full text-center border-none text-white bg-green-600 */
}

.login-wrapper::after{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0000008f;
  z-index: -1;
  border-radius: 8px;
}

.ant-layout-footer{
  padding: 24px 27px !important;
}




/* CSS */
.button-24 {
  border: 0 solid #003dff;
  color: white;
  background-color: #d31f77;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  box-shadow: rgba(37, 44, 97, .15) 0 4px 11px 0, rgba(93, 100, 148, .2) 2px 5px 3px 0;
  box-sizing: border-box;
  cursor: pointer;
  font-family: Hind, system-ui, BlinkMacSystemFont, -apple-system, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  margin: 4px;
  padding: 10px 24px;
  text-align: center;
  text-decoration: inherit;
  text-wrap: nowrap;
  transition: all .2s ease-out;
  transition-behavior: normal;
  white-space-collapse: collapse;
  line-height: 1.15;
}

@media (min-width: 576px) {
  .button-24 {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}

.button-24:after, .button-24:before, .div-flex-items-center-justify-center:after, .div-flex-items-center-justify-center:before, .span-flex-items-center-h-16-w-auto-mr-8-py-2-flex-grow-0-flex-shrink-0-fill-current:after, .span-flex-items-center-h-16-w-auto-mr-8-py-2-flex-grow-0-flex-shrink-0-fill-current:before, .svg-block-h-full:after, .svg-block-h-full:before {
  border: 0 solid #003dff;
  box-sizing: border-box;
}

.button-24:hover {
  box-shadow: rgba(37, 44, 97, .15) 0 8px 22px 0, rgba(93, 100, 148, .2) 3px 4px 6px 2px !important;
  background-color: #aa48c4 !important;
}

.button-24:disabled {
  cursor: not-allowed;
  opacity: .5;
}

/* For Chrome, Safari, Edge */
input:-webkit-autofill {
  background-color: transparent !important; /* Change to your desired background color */
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important; /* Prevent browser from applying its default background */
  color: #000000 !important; /* Change to your desired text color */
}

/* For Firefox */
input:-moz-autofill {
  background-color: transparent !important; /* Change to your desired background color */
  color: #000000 !important; /* Change to your desired text color */
}